body{
    /* margin: 0; */
    font-family: 'Yaldevi', sans-serif;
    /* font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff; */
}

.container-main{padding: 20px;}

.cardAtivo{position: absolute;right: 0;margin-top: -5px;}

.eHijxz{border-radius: 6px !important;}

.iIuArS{border-bottom-left-radius: 6px !important;border-bottom-right-radius: 6px !important;}

.main-sidebar .brand-image{opacity: .8};

.sidebar .os-resize-observer{left: 0px; right: auto;};

.sidebar .os-size-auto-observer{height: calc(100% + 1px); float: left;};

.sidebar .os-content-glue{margin: 0px -8px; width: 249px; height: 251px;};

.sidebar .os-viewport{overflow-y: scroll;};

.sidebar .os-content{padding: 0px 8px; height: 100%; width: 100%;};

.sidebar .os-scrollbar-handle{width: 100%; transform: translate(0px, 0px);}

.data-table-extensions > .data-table-extensions-filter > .icon{background-position: 0 5px !important;}

.nav-tabs-custom .tab-content{margin:20px 0}

.rdt_TableBody .rdt_TableRow{cursor: pointer;}

.idade{position: absolute;right:10px}

.firstUp{text-transform:capitalize;}

.low{text-transform:lowercase;}

.card-consultas-historico{
    cursor: pointer;
}

.recordatorioTitle{
    text-align: center;
    background-color: lightgoldenrodyellow;
    padding: 5px 0;
    margin: 15px 0;
    font-weight: 500;
}

.card-consultas-historico:hover{
    opacity: .7;
}

.login-box{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);  
}

@media only screen and (max-width: 992px){
    .radioSide{
        margin-bottom: 40px !important;
    }  
    .anamneseAtividade{
        margin-top: 20px;
    }  
}

.radioSide{
    display: flex;
    margin-bottom: 50px;
}

.radioSide .form-check:nth-child(2){
    position: absolute;
    margin: 35px 0px;    
}

.radioSide .form-check:nth-child(3){
    position: absolute;
    margin: 35px 80px;    
}

.gebNet.inactive{display: none;}
.gebNet.ctive{display: block;}
